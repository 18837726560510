.body {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 70px);
  overflow: hidden;
}

.main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.fullscreen_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.header {
  height: 65px;
  align-items: center;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: var(--app-white);
  padding: 0 10px;
  border: 0px solid var(--app-border-grey);
  border-top-width: 1px;
  border-bottom-width: 1px;
  z-index: 2;
}

.header_item {
  height: 65px;
  display: flex;
  align-items: center;
}

.main_content_container {
  flex: 4;
  padding: 0 20px;
}

.dropdown_section {
  display: flex;
  width: 80%;
}

.dropdown_title {
  font-family: "Avenir", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: var(--text-primary);
  align-self: center;
  margin-right: 7px;
}

.dropdown {
  width: 200px;
  height: 33px;
  padding: 1px 5px;
}

.dropdown_icon {
  background-color: var(--app-white);
  right: 1.4px;
  height: 90%;
  top: 0.5px;
  border-radius: 35%;
}

.empty_items_container {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side_panel_container {
  padding-top: 15px;
  flex: 1;
  border-left: 1px solid var(--app-border-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.multi_input_container {
  width: 100%;
  justify-content: center;
}

.side_panel_field_container {
  width: 80%;
  max-width: 200px;
  margin-bottom: 20px;
}

.footer_button {
  padding: 14px 0;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: var(--app-white);
  background-color: var(--app-blue);
}

.section_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
}

.section_content_container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table_container {
  width: 100%;
  border-radius: 3.8px;
  margin-top: 10px;
  max-height: calc(100vh - 390px);
  overflow-y: auto;
}

.fullscreen_table_container {
  width: 100%;
  border-radius: 3.8px;
  margin-top: 10px;
  max-height: calc(100vh - 325px);
  overflow-y: auto;
}

.table_row_container {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 20px 10px;
  align-items: flex-start;
}

.row_container {
  display: flex;
  flex-direction: row;
}

.epc_table_container {
  margin-left: auto;
  margin-right: 0;
  width: 80%;
}

.section_content_container {
  flex: 1;
}

.expand_button {
  display: flex;
  column-gap: 4px;
  user-select: none;
  align-items: center;
  color: var(--app-blue);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.expand_icon {
  scale: 0.8;
}

.rotated_icon {
  transform: rotate(90deg);
}

.row_image {
  border: 0.5px solid var(--app-border-grey);
  border-radius: 1px;
  box-shadow: 0px 4px 4px var(--app-border-grey);
  object-fit: contain;
}

.section_text_container {
  display: flex;
  flex-direction: row;
}

.section_label {
  font-weight: bold;
  font-size: var(--font-size-base);
  margin-bottom: 0px;
  margin-right: 0.25em;
}

.section_value {
  font-size: var(--font-size-base);
  margin-bottom: 0px;
  margin-right: 0.25em;
}

.clear_button {
  height: 36px;
  width: 70px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--app-blue);
  border-radius: 3px;
  align-self: flex-end;
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.4px;
  color: var(--app-blue);
  margin-right: 6px;
}

.footer {
  padding: 10px 20px;
  border-top: 1px solid var(--app-border-grey);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  z-index: 2;
}

.breadscrumbs_item {
  font-weight: 600;
  font-size: 18px;
  font-family: "Avenir", sans-serif;
}

.table_header {
  border: 0px solid var(--app-border-grey);
  border-top-width: 1px;
  border-bottom-width: 1px;
  padding: 14px 0;
}

.empty_section {
  padding: 1em 0;
  display: none;
}

.loading_table_row {
  margin-top: 12px;
  height: 80px;
}
